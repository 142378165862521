import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styles from './contact.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Contact from '../components/contact'

const ContactPage = () => (
   <Layout>
      <SEO title="Contact Us" />
      <div className={['page', styles.page__contact].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Contact Us
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               We are happy to assist with any questions or inquiry you may have
               concerning your financial needs.
            </p>
         </div>
         <div className={styles.contact__content}>
            <section className={styles.contact__main}>
               <Contact page="contact" />
            </section>

            <section className={styles.contact__sidebar}>
               <div className={styles.sidebar__section}>
                  <h6 className={styles.section__title}>Corporate Office</h6>
                  <p className={styles.sidebar__text}>
                     <span className={styles.section__row}>
                        SPC Financial, Inc.
                     </span>
                     <span className={styles.section__row}>
                        3202 Tower Oaks Blvd, Ste 400
                     </span>
                     <span className={styles.section__row}>
                        Rockville, MD, USA 20852
                     </span>
                  </p>
                  <OutboundLink
                     href="//google.com/maps/dir//SPC+Financial,+Inc.,+3202+Tower+Oaks+Blvd+%23400,+Rockville,+MD+20852/@39.0593281,-77.220709"
                     className="button button--primary button--small button--has-icon"
                     target="_blank"
                     rel="noopener noreferrer">
                     Maps <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </OutboundLink>
                  <OutboundLink
                     href="//assets.ctfassets.net/wt3nvc89w481/6Wj45zsvBpKaI901bpJOmD/ba6a9e379841d708b8d19f6e124df5a3/Corporate_Directions_-_042019.pdf"
                     className="button button--primary button--clear button--small button--has-icon"
                     target="_blank"
                     rel="noopener noreferrer">
                     Directions <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </OutboundLink>
               </div>

               <div className={styles.sidebar__section}>
                  <h6 className={styles.section__title}>Business Hours</h6>
                  <p className={styles.sidebar__text}>Monday - Friday</p>
                  <p className={styles.sidebar__text}>8:30 AM - 5:30 PM</p>
               </div>

               <div className={styles.sidebar__section}>
                  <h6 className={styles.section__title}>Phone Number</h6>
                  <OutboundLink
                     href="tel:3017706800"
                     className={styles.sidebar__link}>
                     (301) 770&#45;6800
                  </OutboundLink>
               </div>

               <div className={styles.sidebar__section}>
                  <h6 className={styles.section__title}>Fax Number</h6>
                  <p className={styles.sidebar__text}>(301) 770&#45;9031</p>
               </div>

               <div className={styles.sidebar__section}>
                  <h6 className={styles.section__title}>Email Address</h6>
                  <a
                     className={styles.sidebar__link}
                     href="mailto:info@spcfinancial.com"
                     target="_blank"
                     rel="noopener noreferrer">
                     info@spcfinancial.com
                  </a>
               </div>
            </section>
         </div>
      </div>
   </Layout>
)

export default ContactPage
